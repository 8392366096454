import { PageRendererProps } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'
import React from 'react'
import styled from 'styled-components'

import consultImage from '../../assets/static/consult/consult4.jpg'
import ComparisonImage from '../../assets/static/service/bps_comparison.png'
import bpsCompleteDentureImage from '../../assets/static/service/bpsCompleteDenture.png'
import step1Image from '../../assets/static/service/step1.svg'
import step2Image from '../../assets/static/service/step2.svg'
import step3HalfImage from '../../assets/static/service/step3_5.svg'
import step3Image from '../../assets/static/service/step3.svg'
import step4Image from '../../assets/static/service/step4.svg'
import { GridLayout } from '../../components/Grid'
import ServiceLayout from '../../components/Layout/ServiceLayout'
import Seo from '../../components/Seo'
import { ContentTitle } from '../../components/Titles/ContentTitle'
import { space } from '../../constants/length'
import { Secondary } from '../../constants/newColor'
import { H3 } from '../../constants/newText'
import { viewport } from '../../constants/viewport'
import Collapse from './components/Collapse'
import ContentSection, { ContentTextStyle } from './components/ContentSection'
import ListItem from './components/ListItem'

const StyledContentTitle = styled(ContentTitle)`
  margin-bottom: ${space.l}px;
`

const ContentText = styled.div`
  ${ContentTextStyle}
`

const IntroText = styled.div`
  padding-top: ${space.l}px;
`

const ListGroup = styled.div`
  margin-top: ${space.m}px;
`

const TableWrapper = styled.div`
  margin: ${space.m}px ${space.s}px;
`

interface ImgProps {
  maxWidth?: string | number
}

const Img = styled.img<ImgProps>`
  max-width: ${props => (props.maxWidth ? props.maxWidth : '100%')};
  height: auto;
  margin: 0 auto;
`

const StepTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${space.xl}px;
  @media (max-width: ${viewport.tablet}px) {
    margin-top: 0;
  }
`

const H3Title = styled.h3`
  ${H3}
  text-align: left;
  margin-bottom: ${space.m}px;
  @media (max-width: ${viewport.tablet}px) {
    text-align: center;
  }
`

const CustomUl = styled.ul`
  list-style-type: disc;
  margin-left: 24px;
  color: ${Secondary};
  font-weight: 300;
  font-size: 1rem;
  line-height: 2rem;
`

/** BPS吸附式全口假牙 */
const BpsCompleteDenture = (props: PageRendererProps) => {
  const { formatMessage } = useIntl()

  const routes = [
    {
      label: formatMessage({ id: 'breadcrumb.index' }),
      path: '/'
    },
    {
      label: formatMessage({ id: 'service.index' }),
      path: '/service/digital'
    },
    {
      label: formatMessage({ id: 'service.bpsCompleteDenture' }),
      path: '/service/bpsCompleteDenture'
    }
  ]

  return (
    <ServiceLayout routes={routes} consultImage={consultImage} {...props} pageBannerInfo={formatMessage({ id: 'banner.bpsCompleteDenture' })}>
      <Seo
        title='『BPS吸附式全口假牙』：全口無牙患者的新選擇 - 日不落牙醫集團'
        metaDescription='預算有限、又害怕開刀植牙？傳統全口假牙又總是脫落？新型BPS吸附式全口假牙提供給全口無牙患者最高CP值的選擇！日不落牙醫集團擁有台北、新竹、台中、台南的權威級醫師團隊搭配先進醫療設施，提供最穩固的全口假牙。'
      />
      <StyledContentTitle>{formatMessage({ id: 'service.bpsCompleteDenture' })}</StyledContentTitle>
      <ContentText>
        <p>BPS=Biofunctional Prosthetic System強調生物功能性的全口假牙</p>
      </ContentText>
      <ContentSection id='patch' imageUrl={bpsCompleteDentureImage} imagePosition='fluid' imageAlt='BPS吸附式全口假牙'>
        <IntroText>
          <p>
            針對
            <strong>全口無牙患者</strong>
            ，你可以有植牙以外的其他選擇！日不落牙醫集團正式推出新世代的功能性全口活動牙-
            <strong>BPS全口活動假牙</strong>
            ，針對習慣性咬點、下巴移動軌跡，製作出最穩固的全口假牙。
          </p>
          <ListGroup>
            <ListItem content=''>
              <span>
                <strong>特點一：</strong>
                強大的真空吸附力，不用假牙黏著劑，整日穩穩貼牢牙齦
              </span>
            </ListItem>
            <ListItem content=''>
              <span>
                <strong>特點二：</strong>
                無須侵入式手術，即可重獲一口好牙
              </span>
            </ListItem>
            <ListItem content=''>
              <span>
                <strong>特點三：</strong>
                日本歐洲專利材質，經久耐用10年以上
              </span>
            </ListItem>
            <ListItem content=''>
              <span>
                <strong>特點四：</strong>
                全口重建除了All on 4植牙，你還可以有更多更高CP值選擇
              </span>
            </ListItem>
          </ListGroup>
        </IntroText>
      </ContentSection>
      <ContentSection title='BPS吸附式全口假牙 vs 傳統全口假牙'>
        <p>
          最主要是因為{' '}
          <u>
            <strong>材料</strong>
          </u>{' '}
          和{' '}
          <u>
            <strong>印模方式</strong>
          </u>{' '}
          有所不同，影響假牙吸附能力：
        </p>
        <ListGroup>
          <ListItem content=''>
            <span>
              <strong>BPS材料：</strong>
              特別引進歐洲牙材大廠Ivoclar Vivadent，新一代的不變形煮聚系統。
            </span>
          </ListItem>
          <ListItem content=''>
            <span>
              <strong>BPS印模方式：</strong>
              傳統假牙為開口印模，容易讓印模邊緣變形；而BPS改成閉口印模，且同時紀錄患者面弓、唇部高低位置、肌肉運動軌跡，將假牙邊緣製作誤差降至最低。
            </span>
          </ListItem>
        </ListGroup>
      </ContentSection>
      <ContentSection title='BPS吸附式全口假牙製作流程'>
        <GridLayout templateColumns={['100%']} rowGap={['32px', '24px']}>
          <GridLayout templateColumns={['100%', '200px 1fr']} columnGap={['unset', '24px']}>
            <Img maxWidth='200px' src={step1Image} />
            <StepTextWrapper>
              <H3Title>初診：取初模 (60-90分鐘)</H3Title>
              <p>
                拍照取初模分析患者口内牙齒
                <br />
                1. 評估與拍照攝影口內/口外狀況
                <br />
                2. 取齒模及咬合紀錄Bite
                <br />
                3. 製作符合患者口內狀況的印模牙托Individual tray
              </p>
            </StepTextWrapper>
          </GridLayout>
          <GridLayout templateColumns={['100%', '200px 1fr']} columnGap={['unset', '24px']}>
            <Img maxWidth='200px' src={step2Image} />
            <StepTextWrapper>
              <H3Title>第 2 次回診：取主模 (120分鐘)</H3Title>
              <p>
                力學分析，取習慣性咬點與下巴移動軌跡，使BPS達到邊緣封閉和類真空的包覆性效果。
                <br />
                1. 取患者習慣性咬點與下巴移動軌跡做力學的分析
                <br />
                2. 定位完成後，使用不同稠度的印模材料，製作BPS的主要模型
              </p>
            </StepTextWrapper>
          </GridLayout>
          <GridLayout templateColumns={['100%', '200px 1fr']} columnGap={['unset', '24px']}>
            <Img maxWidth='200px' src={step3Image} />
            <StepTextWrapper>
              <H3Title>第 3 次回診：試戴蠟堤假牙 (30分鐘)</H3Title>
              <p>
                測試假牙吸附性，進行翻轉/咬合測試
                <br />
                1. 試戴蠟堤義齒、拍配戴後口内照片
                <br />
                2. 舌頭翻轉測試假牙每一側，確認邊緣密合度
                <br />
                3. 測試完後，配戴細修過的蠟堤義齒，確認義齒咬合高度
              </p>
            </StepTextWrapper>
          </GridLayout>
          <GridLayout templateColumns={['100%', '200px 1fr']} columnGap={['unset', '24px']}>
            <Img maxWidth='200px' src={step3HalfImage} />
            <StepTextWrapper>
              <H3Title>第 3.5 次回診：治療性義齒 (選配)</H3Title>
              <p>
                因為BPS假牙吸附力較強、就像直接恢復有牙生活，為預防長期全口無牙患者無法適應，診所也可提供吸附力較弱的『
                <strong>治療性義齒</strong>
                』，讓患者練習復健、恢復口腔肌肉，調整口內咬合、硬軟組織、顎間關係到穩定狀況，進而適應後續的正式假牙。
              </p>
            </StepTextWrapper>
          </GridLayout>
          <GridLayout templateColumns={['100%', '200px 1fr']} columnGap={['unset', '24px']}>
            <Img maxWidth='200px' src={step4Image} />
            <StepTextWrapper>
              <H3Title>第 4 次回診：完成BPS療程 (30分鐘)</H3Title>
              <p>
                配戴最終BPS全口假牙，進行咬合與食物測試
                <br />
                1. 配戴已完成的義齒、確認外觀
                <br />
                2. 使用不同厚度的咬合紙，做最後一次微調
                <br />
                3. 準備三明治做測試
                <br />
                4. 測試完成，帶生命中不可或缺的義齒回家
              </p>
            </StepTextWrapper>
          </GridLayout>
        </GridLayout>
      </ContentSection>
      <ContentSection title='BPS吸附式全口假牙 vs All-on-4植牙全口重建'>
        <ListGroup>
          <ListItem content=''>
            <span>
              <strong>預算是否足夠：</strong>
              All-on-4 價格高出 BPS 許多
            </span>
          </ListItem>
          <ListItem content=''>
            <span>
              <strong>是否能接受手術：</strong>
              All-on-4 屬於侵入式治療，BPS 屬於非侵入式治療
            </span>
          </ListItem>
          <ListItem content=''>
            <span>
              <strong>適應期：</strong>
              BPS 比起 All-on-4 需要較長的適應時間
            </span>
          </ListItem>
        </ListGroup>
        <TableWrapper>
          <Img src={ComparisonImage} />
        </TableWrapper>
      </ContentSection>
      <ContentSection title='BPS吸附式全口假牙  常見問題'>
        <Collapse title='我是否適合做BPS活動假牙？'>
          <p>大部份牙齒需拔光或是已經全口沒有牙齒狀況的都適合。</p>
        </Collapse>
        <Collapse title='我還有牙齒，可以做BPS活動假牙嗎？'>
          <p>如果上顎或下顎剩餘牙齒沒有拔掉的打算，則該顎無法做BPS</p>
        </Collapse>
        <Collapse title='做完BPS假牙如何適應？'>
          <CustomUl>
            <li>由於有異物感，發音上需要練習 (1-2天便會改善)</li>
            <li>異物感會造成嘔吐反射的狀況 (1-2天便會改善)</li>
            <li>可能有破皮問題 (需要一段時間習慣)</li>
            <li>咀嚼肌能訓練</li>
          </CustomUl>
        </Collapse>
        <Collapse title='BPS假牙可以使用多久？'>
          <p>BPS正常來說是越用越持久哦，因為越使用骨頭越不會吸收。所以得到一副BPS假牙的話，請多多使用它。不要有強大的外力撞擊，基本上不太會壞。</p>
        </Collapse>
        <Collapse title='BPS假牙可以吃甚麼東西？'>
          <p>關鍵在於個人的咀嚼肌！能吃的食物要看個人的咀嚼肌狀況，如果以往都是吃軟爛的食物，咀嚼肌會較無力，需要慢慢訓練，但能恢復到多少力量、費時多久，因人而異。</p>
        </Collapse>
      </ContentSection>
    </ServiceLayout>
  )
}

export default BpsCompleteDenture
